import type { BlitzPage } from "@blitzjs/next"
import { Box, Stack } from "@mui/material"
import { useRouter } from "next/router"
import { Suspense, useEffect } from "react"
import { NextWeekBox } from "app/[business]/shifts/components/NextWeekBox"
import SSPaper from "app/components/SSPaper"
import { WeekOffsetPicker } from "app/components/WeekOffsetPicker"
import { EmployeeQRCode } from "app/employee/[business]/components/EmployeeQRCode"
import { EmployeeShiftScheduleView } from "app/employee/[business]/components/EmployeeShiftScheduleView"
import { useWeekOffset } from "app/hooks/useWeekOffset"
import {
  useBusinessQuery,
  useBusinessTimeSlotsQuery,
  useBusinessMembersQuery,
  useTableWeeksQuery,
  useViewerMemberQuery,
} from "interface/__generated__/react"
import BusinessLayout from "pages/[business]/layout"

const EmployeeBusinessShiftsPage: BlitzPage = () => {
  const router = useRouter()

  const businessLogin = router.query.business as string

  const weekOffset = useWeekOffset()

  const since = router.query.since as string | undefined

  const { data: businessData, error: businessError } = useBusinessQuery({
    variables: {
      login: businessLogin,
    },
  })
  if (businessError) {
    throw businessError
  }
  const business = businessData?.business ?? null

  const { data: tableWeeksData, error: tableWeeksError } = useTableWeeksQuery()

  if (tableWeeksError) {
    throw tableWeeksError
  }

  useEffect(() => {
    if (since != undefined) {
      console.log("since != undefined")

      const tableWeeksIndex = tableWeeksData?.tableWeeks.findIndex(
        (table) => table.since === since,
      )
      if (tableWeeksIndex != null && tableWeeksIndex >= 0) {
        weekOffset.onChange(tableWeeksIndex)
        router.replace(router.asPath.split("?")[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, tableWeeksData?.tableWeeks, weekOffset])

  const { data: timeSlotsData, error: businessTimeSlotsError } =
    useBusinessTimeSlotsQuery({
      variables: {
        businessLogin: businessLogin,
      },
    })
  if (businessTimeSlotsError) {
    throw businessTimeSlotsError
  }
  const businessTimeSlots =
    timeSlotsData?.business?.timeSlots.filter((v) => !v.isDeleted) ?? []

  const businessShiftRoles = business?.shiftRoles ?? []

  const { data: membersData, error: businessMembersError } =
    useBusinessMembersQuery({
      variables: {
        businessLogin: businessLogin,
      },
    })
  if (businessMembersError) {
    throw businessMembersError
  }
  const members = membersData?.business?.members ?? []

  const { data: businessMemberData, error: viewerMemberError } =
    useViewerMemberQuery({
      variables: { businessLogin: businessLogin },
    })
  if (viewerMemberError) {
    throw viewerMemberError
  }
  if (business === null) {
    return null
  }

  const member = businessMemberData?.viewer?.member ?? null

  if (member === null) {
    return null
  }

  const shiftTables = tableWeeksData?.tableWeeks ?? []

  if (shiftTables.length === 0) {
    console.log("shiftTables.length === 0")
    return null
  }

  console.log("shiftTables", shiftTables)
  console.log("weekOffset.offset", weekOffset.offset)
  return (
    <Stack spacing={2} sx={{ px: { xs: 2, sm: 3 }, mt: 2, mb: 2 }}>
      <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
        <SSPaper>
          <Box sx={{ p: 2 }}>
            <EmployeeQRCode checkinCode={member.checkinCode} />
          </Box>
        </SSPaper>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <Box sx={{ maxWidth: "20rem", width: "100%" }}>
          <WeekOffsetPicker
            texts={shiftTables.map((table) => table.weekText)}
            offset={weekOffset.offset}
            onChange={weekOffset.onChange}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <NextWeekBox
            isThisWeek={weekOffset.isThisWeek}
            isNextWeek={weekOffset.isNextWeek}
            onSelectThisWeek={weekOffset.onSelectThisWeek}
            onSelectNextWeek={weekOffset.onSelectNextWeek}
          />
        </Box>
      </Stack>
      <Suspense>
        <SSPaper>
          <EmployeeShiftScheduleView
            key={weekOffset.offset + ""}
            businessLogin={businessLogin}
            members={members}
            businessTimeSlots={businessTimeSlots}
            businessShiftRoles={businessShiftRoles}
            since={shiftTables[weekOffset.offset].since}
            until={shiftTables[weekOffset.offset].until}
          />
        </SSPaper>
      </Suspense>
    </Stack>
  )
}

EmployeeBusinessShiftsPage.getLayout = (page) => {
  return <BusinessLayout title={"Shift"}>{page}</BusinessLayout>
}

export default EmployeeBusinessShiftsPage
