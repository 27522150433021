
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[business]/member/shifts",
      function () {
        return require("private-next-pages/[business]/member/shifts/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[business]/member/shifts"])
      });
    }
  