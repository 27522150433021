import { Stack, Typography } from "@mui/material"
import QRCode from "qrcode.react"
import type { FC } from "react"

type Props = {
  checkinCode: string | null
}

export const EmployeeQRCode: FC<Props> = (props) => {
  const value = JSON.stringify({
    checkinCode: props.checkinCode,
  })

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} spacing={2}>
        <QRCode value={value} style={{ display: "block", borderRadius: 4 }} />
        <Stack justifyContent={"center"} alignItems={"center"} flex={1}>
          <Typography fontWeight={"bold"} fontSize={16}>
            {"Check In Code"}
          </Typography>
          <Typography fontWeight={"bold"} sx={{ fontSize: 48 }}>
            {`${props.checkinCode}`}
          </Typography>
        </Stack>
      </Stack>
      <Typography variant={"caption"}>
        {"You can clock in by scanning the QR code or input this code."}
      </Typography>
    </Stack>
  )
}
