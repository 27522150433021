import { Stack } from "@mui/material"
import { t } from "i18next"
import type { FC } from "react"
import { useState } from "react"
import { EditableNote } from "app/[business]/shifts/components/EditableNote"
import { ShiftTable } from "app/[business]/shifts/components/ShiftTable"
import { ShiftTableAlert } from "app/[business]/shifts/components/ShiftTableAlert"
import { ShiftTableCellCaptions } from "app/[business]/shifts/components/ShiftTableCellCaptions"
import { ShiftTableTabs } from "app/[business]/shifts/components/ShiftTableTabs"
import { ViewHeader } from "app/components/ViewHeader"
import {
  useBusinessAssignableShiftsQuery,
  useBusinessShiftScheduleQuery,
  useBusinessShiftScheduleRequestsQuery,
  useTableDateTextQuery,
} from "interface/__generated__/react"
import type {
  MemberFieldsFragment,
  TimeSlotFieldsFragment,
  ShiftRoleFieldsFragment,
} from "interface/__generated__/react"

type Props = {
  businessLogin: string
  members: MemberFieldsFragment[]
  businessTimeSlots: TimeSlotFieldsFragment[]
  businessShiftRoles: ShiftRoleFieldsFragment[]
  since: string
  until: string
}

/**
 * 従業員のシフトのテーブル
 */
export const EmployeeShiftScheduleView: FC<Props> = (props) => {
  const { data: tableDataTextData, error: tableDateTextError } =
    useTableDateTextQuery({
      variables: { businessLogin: props.businessLogin, since: props.since },
    })
  if (tableDateTextError) {
    throw tableDateTextError
  }
  const { data: shiftScheduleData, error: businessShiftScheduleError } =
    useBusinessShiftScheduleQuery({
      variables: {
        businessLogin: props.businessLogin,
        since: props.since,
        until: props.until,
      },
    })
  if (businessShiftScheduleError) {
    throw businessShiftScheduleError
  }

  const shiftSchedule = shiftScheduleData?.business?.shiftSchedule

  // const { data: shiftRequestsData, error: businessShiftRequestsError } =
  //   useBusinessShiftRequestsQuery({
  //     variables: {
  //       businessLogin: props.businessLogin,
  //       since: props.since,
  //       until: props.until,
  //     },
  //   })
  // if (businessShiftRequestsError) {
  //   throw businessShiftRequestsError
  // }

  const { data: assignableShiftsData, error: businessAssignableShiftsError } =
    useBusinessAssignableShiftsQuery({
      variables: {
        businessLogin: props.businessLogin,
        since: props.since,
        until: props.until,
      },
    })
  if (businessAssignableShiftsError) {
    throw businessAssignableShiftsError
  }

  const {
    data: shiftScheduleRequestsData,
    error: businessShiftScheduleRequestsError,
  } = useBusinessShiftScheduleRequestsQuery({
    variables: {
      businessLogin: props.businessLogin,
      since: props.since,
      until: props.until,
    },
  })
  if (businessShiftScheduleRequestsError) {
    throw businessShiftScheduleRequestsError
  }

  const [tabIndex, setTabIndex] = useState(0)

  const tableShiftRoles = props.businessShiftRoles.filter((_, index) => {
    return tabIndex === 0 || index + 1 === tabIndex
  })

  const isConfirmed = shiftSchedule !== null

  const shiftTable = tableDataTextData?.tableDateText ?? null

  if (shiftTable === null) {
    return null
  }

  const assignableShifts =
    assignableShiftsData?.business?.assignableShifts ?? []

  const shiftScheduleRequests =
    shiftScheduleRequestsData?.business?.shiftScheduleRequests ?? []

  return (
    <Stack spacing={4} sx={{ pt: 2, pb: 1 }}>
      <Stack spacing={2} sx={{ px: 2 }}>
        <ViewHeader title={t("Shift Schedule")} />
        <ShiftTableAlert isConfirmed={isConfirmed} />
      </Stack>
      <EditableNote
        isEditable={false}
        isReadOnly={true}
        note={shiftSchedule?.note ?? ""}
      />
      <ShiftTableTabs
        shiftRoles={props.businessShiftRoles}
        value={tabIndex}
        onChange={setTabIndex}
      />
      <ShiftTable
        dateTexts={shiftTable.dateTexts}
        uiDateTexts={shiftTable.uiDateTexts}
        shiftScheduleRequests={shiftScheduleRequests}
        businessTimeSlots={props.businessTimeSlots}
        members={props.members}
        tableShiftRoles={tableShiftRoles}
        assignableShifts={assignableShifts}
        isEditable={false}
      />

      <ShiftTableCellCaptions />
    </Stack>
  )
}
